import { IOrder } from "./model";

import ParaswapIcon from "./ParaswapIcon";
import TokenlonIcon from "./TokenlonIcon";
import ZeroXIcon from "./ZeroXIcon";
import HashflowIcon from "./HashflowIcon";
import OneInchIcon from "./OneInchIcon";
import KSIcon from "./KSIcon";
import Bebop from "./Bebop";
import Uniswapx from "./Uniswapx";
import Pancake from "./Pancake";

interface IProps {
    value: string;
    original: IOrder;
    className?: string;
}

const ExchangeCell: React.FC<IProps> = ({ value, original, className }) => {
    switch (value.toLowerCase()) {
        case "paraswap":
            return <ParaswapIcon />;
        case "zerox":
            return <ZeroXIcon />;
        case "zerox_rfqv3":
        case "zerox_rfq_v3":
        case "zeroxv3":
            return (
                <div className="d-flex">
                    <ZeroXIcon />
                    <div className="position-relative">
                        <div className="zeroxv3">V3</div>
                    </div>
                </div>
            );
        case "tokenlon":
            return <TokenlonIcon />;
        case "hashflow":
            return <HashflowIcon />;
        case "kyberswap":
            return <KSIcon />;
        case "kyberswaprfq":
            return (
                <div className="d-flex">
                    <KSIcon />
                    <div className="position-relative">
                        <div className="rfq">RFQ</div>
                    </div>
                </div>
            );
        case "1inch":
            return <OneInchIcon />;
        case "1inchv6":
            return (
                <div className="d-flex">
                    <OneInchIcon />
                    <div className="position-relative">
                        <div className="one-inch-v6">V6</div>
                    </div>
                </div>
            );
        case "hashflowv3":
            return (
                <div className="d-flex">
                    <HashflowIcon />
                    <div className="position-relative">
                        <div className="v3">V3</div>
                    </div>
                </div>
            );
        case "bebop":
            return <Bebop />;
        case "pcsx":
            return (
                <div className="d-flex">
                    <Pancake />
                    <div className="position-relative">
                        <div className="pcsx">PcsX</div>
                    </div>
                </div>
            );
        case "uniswapx":
            return (
                <div className="d-flex">
                    <Uniswapx />
                    <div className="position-relative">
                        <div className="uniswapx">UniswapX</div>
                    </div>
                </div>
            );

        default:
            return <div> {value}</div>;
    }
};

export default ExchangeCell;
